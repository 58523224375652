import {
  HomeOutlined,
  // AppstoreOutlined,
  SettingOutlined,
  // CalendarOutlined,
  UsergroupAddOutlined,
  CodeOutlined,
  CheckSquareOutlined,
} from "@ant-design/icons";
import { MdLiveTv } from "react-icons/md";
import chatgpt_icon from "../assets/chatgpt_icon.png";
import claude_icon from "../assets/claude_icon.png";
import gemini_icon from "../assets/gemini_icon.png";

export const shkaleMenuItems = [
  { id: "Home", icon: HomeOutlined, text: "Home", path: "/shkale/chat" },
  { id: "CheckIns", icon: CheckSquareOutlined, text: "Check Ins", path: "/shkale/checkins" },
  {id : "Live", icon : MdLiveTv, text : "live", path : "/live"},
  // { id: "Calender", icon: CalendarOutlined, text: "Calender", isPro: false, path: "/shkale/calender" },
  // { id: "Trends", icon: AppstoreOutlined, text: "Trends", isPro: false, path: "/shkale/trends" },
  { id: "Settings", icon: SettingOutlined, text: "Settings", path: "/shkale/settings" },
];


export const adminMenuItems = [
  { id: "User", icon: UsergroupAddOutlined, text: "Users", path: "/admin/users" },
  { id: "Prompt", icon: CodeOutlined, text: "Prompt", path: "/admin/prompt" },
  // { id: "Switch", icon: UserSwitchOutlined, text: "Switch User", path: "/shkale/chat" }
];

export const models = [
  {
    key: "GPT-4o",
    name: "GPT-4o",
    description: "Open AI language model.",
    icon: chatgpt_icon,
    disabled: false
  },
  {
    key: "Gemini",
    name: "Gemini",
    description: "Google AI language model.",
    icon: gemini_icon,
    disabled: false
  },
  {
    key: "Claude-3.5",
    name: "Claude-3.5",
    description: "Anthropic's AI language model.",
    icon: claude_icon,
    disabled: true
  },
];
