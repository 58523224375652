import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkBreaks from "remark-breaks";
import remarkGfm from "remark-gfm";
import DynamicActionIcons from "./DynamicActionIcons";
import logo_pom_blue from "../../assets/logo_pom_blue.png";
import { RedoOutlined } from "@ant-design/icons";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark, darcula } from "react-syntax-highlighter/dist/esm/styles/prism";

const Messages = ({
  messages,
  loading,
  regenrateMsg,
  regenrateResponse,
  setAIPromptTemplate,
  updateMessage,
}) => {
  const [animation, setAnimation] = useState("");
  const handleClick = (activeMsgDtls, icon) => {
    setAnimation(icon);
    setTimeout(() => setAnimation(""), 1000);
    if (icon === "like") {
      updateMessage({
        ...activeMsgDtls,
        like: !activeMsgDtls.like,
        dislike: activeMsgDtls.like,
      });
    }
    if (icon === "dislike") {
      updateMessage({
        ...activeMsgDtls,
        dislike: !activeMsgDtls.dislike,
        like: activeMsgDtls.dislike,
      });
    }
    if (icon === "Read Aloud") {
      console.log("sound if block", activeMsgDtls);
      const plainText = activeMsgDtls.content;
      speak(plainText);
    }
    if (icon === "stop-sound") {
      stopCurrentSpeaking();
    }
  };
  return (
    <>
      <div className="  [&>*:nth-child(odd)]:ml-auto" >
        {messages?.map((msg, index) => {
          const { type, data, entities } = msg.history;
          return type === "ai" ? (
            <div className="m-4 w-fit chat-wrap-container  flex flex-row gap-2">
              <div>
                <img
                  src={logo_pom_blue}
                  alt="content"
                  className="w-10 h-10"
                  // style={{ borderRadius: "50%" }}
                />
              </div>
              <div className=" w-full max-w-full">
                <div className="text-[#1e233a] font-medium mb-2">Shkale</div>
                <div className="bg-[#228B22] text-white p-4 m-4 rounded-xl rounded-tl-none overflow-wrap break-word">
                  <div className="" id={msg._id}>
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm, remarkBreaks]}
                      rehypePlugins={[rehypeRaw]}
                      children={data.content?.trim()?.replace(/\n/gi, "&nbsp; \n")}
                      components={{
                        code(props) {
                          const { children, className, node, ...rest } = props;
                          const match = /language-(\w+)/.exec(className || "");
                          return match ? (
                            <SyntaxHighlighter
                              {...rest}
                              PreTag="div"
                              children={String(children).replace(/\n$/, "")}
                              language={match[1]}
                              style={darcula}
                            />
                          ) : (
                            <code {...rest} className={className}>
                              {children}
                            </code>
                          );
                        },
                      }}
                    />
                  </div>x
                </div>
                <DynamicActionIcons
                  isLastMessage={index === messages.length - 1}
                  isAI={true}
                  activeMsgDtls={{ ...msg, content: data.content }}
                  animation={animation}
                  handleClick={handleClick}
                  setAIPromptTemplate={(template) =>
                    setAIPromptTemplate(template)
                  }
                  companies={data?.companies || ""}
                  company_regNo={data?.company_regNo || ""}
                  message={data.content?.replace(/\n/gi, "&nbsp; \n")}
                />
              </div>
            </div>
          ) : (
            <div className="m-4 w-fit max-w-full flex flex-row gap-2">
              <div className="w-10 h-10"></div>
              <div className=" w-full">
                {/* <div className="text-[#1e233a] font-mediu÷m mb-2">You:</div> */}
                <div className="bg-[#33475b] text-white p-4 m-4 rounded-xl rounded-tr-none">
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm, remarkBreaks]}
                    rehypePlugins={[rehypeRaw]}
                    children={data.content?.replace(/\n/gi, "&nbsp; \n")}
                    components={{
                      code(props) {
                        const { children, className, node, ...rest } = props;
                        const match = /language-(\w+)/.exec(className || "");
                        return match ? (
                          <SyntaxHighlighter
                            {...rest}
                            PreTag="div"
                            children={String(children).replace(/\n$/, "")}
                            language={match[1]}
                            style={darcula}
                          />
                        ) : (
                          <code {...rest} className={`${className} overflow-auto`}>
                            {children}
                          </code>
                        );
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
        {regenrateMsg ? (
          <div
            className="flex justify-center cursor-pointer"
            onClick={() => regenrateResponse()}
          >
            <span className="bg-red-800 text-white px-32 rounded-lg">
              Something went wrong! <RedoOutlined />
            </span>
          </div>
        ) : null}
      </div>
      {loading && (
        <div
          className="grid m-4"
          style={{ display: "grid", gridTemplateColumns: "7% 90%" }}
        >
          <div className="grid">
            <img
              src={logo_pom_blue}
              alt="content"
              className="w-10 h-10"
              // style={{ borderRadius: "50%" }}
            />
          </div>

          <div style={{ gridTemplateColumns: "auto" }}>
            <div className="text-[#1e233a] font-medium mb-2">Shkale</div>
            <div className="snippet mx-5" dataTilte="dot-falling">
              <div className="stage">
                <div className="dot-falling"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Messages;
